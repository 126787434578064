import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { About, PageTitle, Team } from "@components"

const AboutUs = () => (
  <Layout>
    <Seo title="About us" />
    <PageTitle title="About us" />
    <Team />
    <About />
  </Layout>
)

export default AboutUs
